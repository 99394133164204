import { useFetchWrapper } from '../utils/fetch-wrapper';

export const usePromotionActions = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/promotion`;

    const fetchWrapper = useFetchWrapper();

    const getPromotion = () => {
        return fetchWrapper.get(`${baseUrl}`);
    };

    const checkEligibilty = (email: string) => {
        return fetchWrapper.post(`${baseUrl}/check-eligibility`, { email });
    };

    const createPromotionPaymentIntent = (
        email: string,
        promotionId: number | null,
        newUserData?: { isNewUser?: boolean; password?: string; name?: string; phoneNumber?: string },
    ) => {
        const splittedName = (newUserData?.name || '').split(' ');
        const firstName = splittedName.length > 0 ? splittedName[0] : '';
        const lastName = splittedName.length > 1 ? splittedName.shift() && splittedName.join(' ') : '';

        return fetchWrapper.post(`${baseUrl}/create-promotion-payment-intent`, { ...newUserData, email, promotionId, firstName, lastName });
    };

    return { checkEligibilty, getPromotion, createPromotionPaymentIntent };
};
