import { useFetchWrapper } from '../utils/fetch-wrapper';

export const usePaymentActions = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/payment`;

    const fetchWrapper = useFetchWrapper();

    const notifySuccessPaymentIntent = (paymentIntentId: string) => {
        return fetchWrapper.post(`${baseUrl}/notify-success-payment-intent`, { paymentIntentId });
    };

    return { notifySuccessPaymentIntent };
};
