import { atom } from 'recoil';

export interface IPromotion {
    id: number | null;
    title: string;
    name: string;
    description: string;
    banner_url: string;
    subscription_day: number | null;
    currency: string;
    price: number | null;
    old_price: number | null;
    start_date: string;
    end_date: string;
    created_at: string;
    updated_at: string;
    deleted_at: Date | null;
}

export const promotionStore = atom<IPromotion>({
    key: 'promotionStore',
    default: {
        id: null,
        title: '',
        name: '',
        description: '',
        banner_url: '',
        subscription_day: null,
        currency: '',
        price: null,
        old_price: null,
        start_date: '',
        end_date: '',
        created_at: '',
        updated_at: '',
        deleted_at: null,
    },
});
