import React, { useRef } from 'react';

import { Input, Box, Text, Button, Link, Spinner, Flex, Container, Image, Wrap, WrapItem, Skeleton, Center, Spacer, Stack, IconButton } from '@chakra-ui/react';
import Carousel, { slidesToShowPlugin, autoplayPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import moment from 'moment';
import 'moment/locale/it';

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

import { ShowIf } from '../../components/ShowIf';

import { PromotionComponentProps } from './interface';

moment.locale('it');

export const PromotionComponent: React.FC<PromotionComponentProps> = (props) => {
    const { onSubmit, onChange, values, errors, isLoading, isNewUser, isDocumentReady, promotion } = props;
    const activationFormRef = useRef<null | HTMLDivElement>(null);

    const carouselImages = [
        'https://play.spika.it/assets/adminassets/course_images/1620656834_cd48b72d4182f1d1fa89.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1620656861_ddf33f0bf8945a3f5de5.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1620656989_a319167da1ea16ff6509.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1620657017_c5c6ca69e5c6ee65a21a.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1620657054_10996c32d6708d43e19d.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1620811515_e270bd1fdb91c762f6e4.jpg',
        'https://play.spika.it/assets/adminassets/course_images/1623864764_2ce14ff15e0a0a1684a8.jpg',
        'https://play.spika.it/assets/adminassets/course_images/1626941992_e37601ba3e72431a47c2.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1631609895_47de92e24dce52479d5d.jpg',
        'https://play.spika.it/assets/adminassets/course_images/1634921564_2adfd63313e34d4ae4c8.jpeg',
        'https://play.spika.it/assets/adminassets/course_images/1635181206_ec48150fdb464d8a17f1.jpeg',
    ];

    const carouselPlugins = [
        'infinite',
        {
            resolve: arrowsPlugin,
            options: {
                arrowLeft: <IconButton variant="outline" aria-label="carousel control" icon={<ArrowBackIcon fontSize="2xl" color="white" />} borderWidth={0} />,
                arrowLeftDisabled: (
                    <IconButton
                        variant="outline"
                        aria-label="carousel control"
                        icon={<ArrowBackIcon fontSize="2xl" color="white" />}
                        disabled
                        borderWidth={0}
                    />
                ),
                arrowRight: (
                    <IconButton variant="outline" aria-label="carousel control" icon={<ArrowForwardIcon fontSize="2xl" color="white" />} borderWidth={0} />
                ),
                arrowRightDisabled: (
                    <IconButton
                        variant="outline"
                        aria-label="carousel control"
                        icon={<ArrowForwardIcon fontSize="2xl" color="white" />}
                        disabled
                        borderWidth={0}
                    />
                ),
                addArrowClickHandler: true,
            },
        },
        {
            resolve: autoplayPlugin,
            options: {
                interval: 1500,
            },
        },
    ];

    return (
        <Flex w="full" direction="column">
            <Container maxW="full" bg="#0084CE" minH="64px" pt="15px">
                <Container maxW="8xl">
                    <Wrap justify="stretch">
                        <Image src="/spika-logo-light.png" h="28px" />
                        <Spacer display={{ base: 'none', md: 'block' }} />

                        <Button
                            variant="outline"
                            colorScheme="white"
                            color="white"
                            size="sm"
                            px="30px"
                            onClick={() => {
                                window.location.href = 'https://play.spika.it';
                            }}
                        >
                            Login
                        </Button>
                    </Wrap>
                </Container>
            </Container>
            <Container maxW="full" bg="#0C1831" minH="600px">
                <Container maxW="8xl">
                    <ShowIf condition={isDocumentReady}>
                        <Wrap align="center" direction="row-reverse" justify="center" py="40px" w="full" spacing={4}>
                            <WrapItem>
                                <Skeleton isLoaded={isDocumentReady}>
                                    <Image src={promotion?.banner_url} w="100%" borderRadius={4} />
                                </Skeleton>
                            </WrapItem>
                            <Spacer />
                            <WrapItem>
                                <Box>
                                    <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={800} color="#BB0032">
                                        {promotion?.title}
                                    </Text>
                                    <Text fontSize={{ base: '4xl', md: '6xl' }} fontWeight={800} color="white" lineHeight={1} whiteSpace="pre-line">
                                        {promotion?.name.split(' ').join('\n')}
                                    </Text>

                                    <Text fontSize="lg" fontWeight={500} mt={8} mb={4} color="gray.200" maxW={700} whiteSpace="pre-line" letterSpacing={0.1}>
                                        {promotion?.description.split('\\n').join('\n')}
                                    </Text>

                                    <Button
                                        colorScheme="blue"
                                        onClick={() => {
                                            activationFormRef?.current?.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                        my={8}
                                    >
                                        Attivami l’offerta!
                                    </Button>
                                </Box>
                            </WrapItem>
                        </Wrap>
                    </ShowIf>
                </Container>
            </Container>
            <Container maxW="full" bg="#0084CE" minH="400px">
                <Container maxW="8xl">
                    <Stack align="center" mt={8} mb={20}>
                        <Text fontWeight={300} fontSize="3xl" color="white" mt={1} mb={5}>
                            Le serie in piattaforma:
                        </Text>

                        <Carousel
                            breakpoints={{
                                320: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1,
                                            },
                                        },
                                    ],
                                },
                                400: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1,
                                            },
                                        },
                                    ],
                                },
                                500: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1,
                                            },
                                        },
                                    ],
                                },
                                600: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1,
                                            },
                                        },
                                    ],
                                },
                                768: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 2,
                                            },
                                        },
                                    ],
                                },
                                1125: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 3,
                                            },
                                        },
                                    ],
                                },
                                1280: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 4,
                                            },
                                        },
                                    ],
                                },
                                1440: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 5,
                                            },
                                        },
                                    ],
                                },
                                9999: {
                                    plugins: [
                                        ...carouselPlugins,
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 6,
                                            },
                                        },
                                    ],
                                },
                            }}
                            animationSpeed={1000}
                        >
                            {carouselImages.map((url) => (
                                <Image src={url} h={300} fallbackSrc="https://via.placeholder.com/150x225?text=." />
                            ))}
                        </Carousel>
                    </Stack>
                </Container>
            </Container>
            <Container maxW="full" bg="#0C1831" minH="250px">
                <Container maxW="8xl">
                    <Stack align="center" mt={8}>
                        <Text fontWeight={800} fontSize="4xl" color="white" mt={1} mb={5}>
                            Offerta a tempo!
                        </Text>
                        <Text fontWeight={300} fontSize="lg" color="white" mt={1} mb={5} align="center">
                            Puoi ottenere 2 mesi di Spika Play a soli €{promotion?.price}, invece di €{promotion?.old_price}
                            <br /> Iscriviti entro il <b>{promotion?.end_date ? moment(promotion.end_date).format('DD MMMM YYYY') : <Spinner size="xs" />}</b>
                        </Text>
                    </Stack>
                </Container>
            </Container>
            <Container maxW="full" bg="#0C1831" minH="350px">
                <Container maxW="5xl">
                    <Wrap align="center" justify="center" pt="20px">
                        <WrapItem>
                            <Box maxW="400px" mt={1} mb={6}>
                                <Skeleton isLoaded={isDocumentReady} variant="">
                                    <Text fontWeight={800} fontSize="3xl" color="white" mt={1} mb={5}>
                                        Attivami l’offerta!
                                    </Text>
                                </Skeleton>

                                <Skeleton isLoaded={isDocumentReady}>
                                    <Text fontSize="sm" color="gray.100">
                                        Offerta valida per i nuovi utenti e anche per chi ha già provato Spika Play in passato. Non potrai usufruire di questa
                                        offerta se hai un abbonamento attivo. Offerta non cumulabile con un abbonamento attivo e con altre promozioni. Attiva
                                        l'offerta attraverso questa pagina entro il{' '}
                                        {promotion?.end_date ? moment(promotion.end_date).format('DD MMMM YYYY') : <Spinner size="xs" />}. Buon apprendimento!
                                    </Text>
                                </Skeleton>
                            </Box>
                        </WrapItem>
                        <Spacer display={{ base: 'none', md: 'block' }} />
                        <WrapItem>
                            <Center>
                                <Skeleton isLoaded={isDocumentReady} variant="">
                                    <Box bg="white" p={5} mt={8} borderRadius="lg" w={{ base: '90vw', sm: '400px' }} my={6} mb={12} ref={activationFormRef}>
                                        <form onSubmit={onSubmit}>
                                            <Text fontSize="xs" color="gray.700" mb={1}>
                                                Email Address
                                            </Text>
                                            <Input
                                                placeholder="Enter your email"
                                                id="email"
                                                name="email"
                                                mb={!!errors.email && !!values.email ? 1 : 3}
                                                isInvalid={!!errors.email}
                                                onChange={onChange}
                                                value={values.email}
                                            />

                                            <ShowIf condition={!!errors.email && !!values.email}>
                                                <Text fontSize="xs" color="red.500" mb={3}>
                                                    {errors.email}
                                                </Text>
                                            </ShowIf>

                                            <ShowIf condition={isNewUser}>
                                                <Text fontSize="sm" color="gray.500" mb={3}>
                                                    Welcome to Spika Play! We detect you are a new user in our platform. Please input password below for your
                                                    Spika Play account
                                                </Text>

                                                <Text fontSize="xs" color="gray.700" mb={1}>
                                                    Your name
                                                </Text>
                                                <Input
                                                    placeholder="Enter your name"
                                                    id="name"
                                                    name="name"
                                                    mb={!!errors.name ? 1 : 3}
                                                    isInvalid={!!errors.name}
                                                    onChange={onChange}
                                                    value={values.name}
                                                />
                                                <ShowIf condition={!!errors.name}>
                                                    <Text fontSize="xs" color="red.500" mb={3}>
                                                        {errors.name}
                                                    </Text>
                                                </ShowIf>

                                                <Text fontSize="xs" color="gray.700" mb={1}>
                                                    Phone number (optional)
                                                </Text>
                                                <Input
                                                    placeholder="Enter your phone number"
                                                    type="tel"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    mb={!!errors.phoneNumber ? 1 : 3}
                                                    isInvalid={!!errors.phoneNumber}
                                                    onChange={onChange}
                                                    value={values.phoneNumber}
                                                />

                                                <ShowIf condition={!!errors.phoneNumber}>
                                                    <Text fontSize="xs" color="red.500" mb={3}>
                                                        {errors.phoneNumber}
                                                    </Text>
                                                </ShowIf>

                                                <Text fontSize="xs" color="gray.700" mb={1}>
                                                    New Password
                                                </Text>
                                                <Input
                                                    placeholder="Enter your password"
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    mb={!!errors.password || !!errors.confirmPassword ? 1 : 3}
                                                    isInvalid={!!errors.password || !!errors.confirmPassword}
                                                    onChange={onChange}
                                                    value={values.password}
                                                />

                                                <ShowIf condition={!!errors.password || !!errors.confirmPassword}>
                                                    <Text fontSize="xs" color="red.500" mb={3}>
                                                        {errors.password || errors.confirmPassword}
                                                    </Text>
                                                </ShowIf>

                                                <Text fontSize="xs" color="gray.700" mb={1}>
                                                    Re-enter New Password
                                                </Text>
                                                <Input
                                                    placeholder="Re-enter your password"
                                                    type="password"
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    mb={!!errors.confirmPassword ? 1 : 3}
                                                    isInvalid={!!errors.confirmPassword}
                                                    onChange={onChange}
                                                    value={values.confirmPassword}
                                                />

                                                <ShowIf condition={!!errors.confirmPassword}>
                                                    <Text fontSize="xs" color="red.500" mb={3}>
                                                        {errors.confirmPassword}
                                                    </Text>
                                                </ShowIf>
                                            </ShowIf>

                                            <Text fontSize="xs" color="gray.500" my={4}>
                                                By continuing, it means you agree to our Registration Conditions. If you want to know more about how Spika Play
                                                will process your personal data, read our{' '}
                                                <Link fontWeight={600} href="https://www.iubenda.com/privacy-policy/83752897" isExternal>
                                                    Privacy Policy
                                                </Link>{' '}
                                                and{' '}
                                                <Link fontWeight={600} href="https://www.iubenda.com/privacy-policy/83752897/cookie-policy" isExternal>
                                                    Cookie Policy
                                                </Link>
                                                .
                                            </Text>

                                            <Button w="full" colorScheme="blue" type="submit" disabled={!values.email}>
                                                {isLoading ? <Spinner size="sm" /> : 'Continue →'}
                                            </Button>
                                        </form>
                                    </Box>
                                </Skeleton>
                            </Center>
                        </WrapItem>
                    </Wrap>
                </Container>
            </Container>

            {/* Footer */}
            <Container maxW="full" bg="gray.700" minH="150px">
                <Container maxW="4xl">
                    <Wrap justify="stretch" mt={12} mb={5}>
                        <Image src="/spika-logo-dark.svg" h="24px" style={{ filter: 'grayscale(100%)' }} />
                        <Spacer display={{ base: 'none', md: 'block' }} />

                        <Text size="xs" color="gray.500">
                            Copyright © {new Date().getFullYear()} Spika. All rights reserved.
                        </Text>
                    </Wrap>
                    <Wrap justify="stretch" mb={12}>
                        <WrapItem>
                            <Link size="xs" href="https://www.iubenda.com/privacy-policy/83752897" isExternal color="gray.300">
                                Privacy Policy
                            </Link>
                        </WrapItem>

                        <Spacer display={{ base: 'none', md: 'block' }} />

                        <WrapItem>
                            <Link size="xs" href="https://www.iubenda.com/privacy-policy/83752897/cookie-policy" isExternal color="gray.300">
                                Cookie Policy
                            </Link>
                        </WrapItem>

                        <Spacer display={{ base: 'none', md: 'block' }} />

                        <WrapItem>
                            <Link size="xs" href="https://www.iubenda.com/termini-e-condizioni/83752897" isExternal color="gray.300">
                                Termini e condizioni d’uso
                            </Link>
                        </WrapItem>
                    </Wrap>
                </Container>
            </Container>
        </Flex>
    );
};
