enum FetchMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export function useFetchWrapper() {
    const request = (method: FetchMethod) => {
        return async (url: string, body?: Record<string, any>) => {
            const requestOptions: Record<string, any> = {
                method,
                headers: authHeader(),
            };

            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            const response = await fetch(url, requestOptions);

            return handleResponse(response);
        };
    };

    const authHeader = () => {
        return {
            'X-Api-Key': process.env.REACT_APP_X_API_KEY,
        };
    };

    const handleResponse = async (response: Response) => {
        const text = await response.text();
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return { error };
        }

        return data;
    };

    return {
        get: request(FetchMethod.GET),
        post: request(FetchMethod.POST),
        put: request(FetchMethod.PUT),
        delete: request(FetchMethod.DELETE),
    };
}
