import { atom } from 'recoil';

export const userStore = atom({
    key: 'userStore',
    default: {
        email: '',
        isNewUser: false,
        password: '',
        phoneNumber: '',
        name: '',
    },
});
