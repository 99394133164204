import React, { useState, useEffect } from 'react';

import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { userStore } from '../../store/user';
import { PromotionComponent } from '../../components/Promotion';
import { ROUTE_PAY_NOW } from '../../pages/routes';
import { usePromotionActions } from '../../actions/promotion.actions';
import { promotionStore } from '../../store/promotion';

import { PromotionPageProps } from './interface';

const UserSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
    name: yup.string().optional(),
    phoneNumber: yup
        .string()
        .optional()
        .matches(/^[0-9+]+$/, 'Only numbers and country code are allowed for this field '),
    password: yup.string().optional(),
    confirmPassword: yup
        .string()
        .optional()
        .oneOf([yup.ref('password'), null], "Password doesn't match"),
});

export const PromotionPage: React.FC<PromotionPageProps> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNewUserFlag, setIsNewUserFlag] = useState<boolean>(false);
    const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);

    const setUser = useSetRecoilState(userStore);
    const userState = useRecoilValue(userStore);

    const setPromotion = useSetRecoilState(promotionStore);
    const promotionState = useRecoilValue(promotionStore);

    const { getPromotion, checkEligibilty } = usePromotionActions();
    const history = useHistory();

    useEffect(() => {
        const getPromotionHandler = async () => {
            const promotion = await getPromotion();

            // no promotion active is found
            if (!!promotion.error) {
                window.location.href = 'https://join.spika.it/';
            }

            setPromotion({ ...promotionState, ...promotion });
            setIsDocumentReady(true);
        };

        setIsDocumentReady(false);
        getPromotionHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            phoneNumber: '',
            name: '',
        },
        validationSchema: UserSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            const checkEligibilityHandler = async (email: string) => {
                const eligibility = await checkEligibilty(email);
                const { isEligible, isNewUser } = eligibility;

                if (isNewUserFlag) {
                    if (!values.password) formik.setFieldError('password', 'Password is required');
                    if (!values.confirmPassword) formik.setFieldError('confirmPassword', 'Confirm password is required');
                    if (!values.name) formik.setFieldError('name', 'Name is required');
                }

                if (!isEligible) {
                    formik.setFieldError('email', 'You are not eligible for this promotion');
                } else {
                    if (isNewUser) {
                        setUser({
                            ...userState,
                            email: values.email,
                            name: values.name,
                            phoneNumber: values.phoneNumber,
                            password: values.password,
                            isNewUser: true,
                        });
                    } else {
                        setUser({ ...userState, email: values.email, name: '', phoneNumber: '', password: '', isNewUser: false });
                    }

                    if (!isNewUserFlag && isNewUser) {
                        setIsNewUserFlag(isNewUser);
                    } else if (!isNewUser || (isNewUser && values.password && values.confirmPassword && values.name)) {
                        history.push(ROUTE_PAY_NOW);
                    }
                }

                setIsLoading(false);
            };

            setIsLoading(true);
            checkEligibilityHandler(values.email);
        },
    });

    return (
        <PromotionComponent
            values={formik.values}
            errors={formik.errors}
            onSubmit={formik.handleSubmit}
            onChange={formik.handleChange}
            isLoading={isLoading}
            isNewUser={isNewUserFlag}
            promotion={promotionState}
            isDocumentReady={isDocumentReady}
        />
    );
};
