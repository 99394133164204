import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import '@brainhubeu/react-carousel/lib/style.css';

import { PromotionPage } from './pages/Promotion';
import { PayNowPage } from './pages/PayNow';
import { theme } from './theme';
import { ROUTE_PAY_NOW, ROUTE_PROMOTION } from './pages/routes';

const App: React.FC = () => {
    return (
        <RecoilRoot>
            <ChakraProvider theme={theme}>
                <Router>
                    <Switch>
                        <Route path={ROUTE_PAY_NOW} component={PayNowPage} />
                        <Route path={ROUTE_PROMOTION} component={PromotionPage} />
                    </Switch>
                </Router>
            </ChakraProvider>
        </RecoilRoot>
    );
};

export default App;
