import React, { useEffect, useState } from 'react';

import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { PayNowComponent } from '../../components/PayNow';
import { promotionStore } from '../../store/promotion';
import { ROUTE_PROMOTION } from '../../pages/routes';
import { usePaymentActions } from '../../actions/payment.actions';
import { usePromotionActions } from '../../actions/promotion.actions';
import { userStore } from '../../store/user';

import { PayNowPageProps } from './interface';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const PaymentForm: React.FC = () => {
    const userState = useRecoilValue(userStore);
    const promotionState = useRecoilValue(promotionStore);
    const toast = useToast();

    const stripe = useStripe();
    const elements = useElements();
    const { notifySuccessPaymentIntent } = usePaymentActions();
    const { createPromotionPaymentIntent } = usePromotionActions();

    const history = useHistory();
    const [clientSecret, setClientSecret] = useState<string>('');
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

    if (!userState.email || !promotionState.id) {
        history.push(ROUTE_PROMOTION);
    }

    const handlePay = async () => {
        const handleNotifySuccessPaymentIntent = async (paymentIntentId: string) => {
            try {
                await notifySuccessPaymentIntent(paymentIntentId);

                setIsPaymentLoading(false);
                toast({
                    title: 'Payment success.',
                    description: "We've processed your payment. You will be redirected to login page in 5 seconds.",
                    status: 'success',
                    duration: 5000,
                    isClosable: false,
                });
                setTimeout(() => {
                    window.location.href = 'https://play.spika.it';
                }, 5000);
            } catch (error) {
                setIsPaymentLoading(false);
            }
        };

        setIsPaymentLoading(true);

        const payload = await stripe!.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements!.getElement(CardElement)!,
            },
        });

        if (payload.error) {
            setIsPaymentLoading(false);
            toast({
                title: 'Payment failed.',
                description: payload.error.message,
                status: 'error',
                duration: 5000,
                isClosable: false,
            });
        } else {
            handleNotifySuccessPaymentIntent(payload.paymentIntent.id);
        }
    };

    useEffect(() => {
        const handleCreatePromotionPaymentIntent = async () => {
            const promotionPaymentIntent = await createPromotionPaymentIntent(userState.email, promotionState.id, userState);
            setClientSecret(promotionPaymentIntent.clientSecret);
        };

        try {
            handleCreatePromotionPaymentIntent();
        } catch (e) {
            toast({
                title: 'Something went wrong',
                description: (e as any).message,
                status: 'error',
                duration: 5000,
                isClosable: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PayNowComponent
            handlePay={handlePay}
            email={userState.email}
            promotion={promotionState}
            isPaymentIntentCreated={!!clientSecret}
            isPaymentLoading={isPaymentLoading}
        />
    );
};

export const PayNowPage: React.FC<PayNowPageProps> = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );
};
