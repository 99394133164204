import React from 'react';

import { Box, Button, Center, Container, Divider, Flex, Link, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr, Spinner } from '@chakra-ui/react';
import { CardElement } from '@stripe/react-stripe-js';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import 'moment/locale/it';

import { ShowIf } from '../../components/ShowIf';
import { PayNowComponentProps } from './interface';

export const PayNowComponent: React.FC<PayNowComponentProps> = (props) => {
    const {
        handlePay,
        email,
        promotion: { name, old_price, price, currency, end_date },
        isPaymentIntentCreated,
        isPaymentLoading,
    } = props;

    return (
        <Flex w="full" h="100vh" bg="gray.100">
            <Container maxW="3xl" mt={8} mb={4}>
                <Center>
                    <ShowIf condition={!isPaymentIntentCreated}>
                        <Spinner />
                    </ShowIf>

                    <ShowIf condition={isPaymentIntentCreated}>
                        <Box maxW="sm" minH={300} borderWidth="1px" borderRadius="lg" p="6" bg="white">
                            <Text fontSize="xs" mb={2} color="gray.500">
                                Spika Play Platform Subscription for
                            </Text>
                            <Text fontSize="lg" mb={7} fontWeight={700}>
                                {email}
                            </Text>

                            <Table variant="simple" borderWidth="1px" fontSize="xs" mb={2} borderRadius="lg">
                                <Thead>
                                    <Tr>
                                        <Th>Item</Th>
                                        <Th isNumeric>Qty</Th>
                                        <Th isNumeric>Price</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{name}</Td>
                                        <Td isNumeric>1</Td>
                                        <Td isNumeric>
                                            <Text as="s" color="gray.500">
                                                {getSymbolFromCurrency(currency.toUpperCase())}
                                                {old_price}
                                            </Text>{' '}
                                            {getSymbolFromCurrency(currency.toUpperCase())}
                                            {price}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            Monthly subscription<sup>*</sup>
                                        </Td>
                                        <Td isNumeric>1</Td>
                                        <Td isNumeric>
                                            {getSymbolFromCurrency(currency.toUpperCase())}
                                            {12.97}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                            <Text fontWeight={300} color="gray.500" fontSize="10px" mb={5}>
                                <sup>*</sup>Monthy subscription will be charged after the promotion ends ({moment(end_date).format('DD MMMM YYYY')})
                            </Text>

                            <Flex mb={5} px={4}>
                                <Text fontWeight={700} color="gray.500" fontSize="lg">
                                    Total Amount
                                </Text>
                                <Spacer />
                                <Text fontWeight={700} color="gray.900" fontSize="lg">
                                    {getSymbolFromCurrency(currency.toUpperCase())}
                                    {price}
                                </Text>
                            </Flex>

                            <Divider my={6} />

                            <Text fontWeight={700} mb={1}>
                                Pay this promo
                            </Text>
                            <Text fontSize="xs" mb={3} color="gray.500">
                                Make payment for this subscription promotion by filling in the details. This payment is powered by{' '}
                                <Link href="https://stripe.com" fontWeight={700} isExternal>
                                    Stripe
                                </Link>
                                .
                            </Text>

                            <Box mb={5} borderWidth="1px" p={4} borderRadius="lg" my={4}>
                                <CardElement />
                            </Box>

                            <Center>
                                <Button w="100%" onClick={handlePay} colorScheme="blue" disabled={isPaymentLoading}>
                                    {isPaymentLoading ? <Spinner /> : `Pay ${getSymbolFromCurrency(currency.toUpperCase())}${price} →`}
                                </Button>
                            </Center>
                        </Box>
                    </ShowIf>
                </Center>
            </Container>
        </Flex>
    );
};
